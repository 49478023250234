
































































































































































import { Observer } from 'mobx-vue'
import { Component, Provide, Vue, Watch } from 'vue-property-decorator'
import { CommunityDetailViewModel } from '@/modules/community-organize/viewmodels/community-detail-viewmodel'
import { createPostController } from '@/modules/common/dialogs/create-post/create-post-controller'

@Observer
@Component({
  components: {
    'profile-info': () => import('@/modules/community-organize/components/community-detail/profile-info.vue'),
    'posts-section': () => import('@/modules/community-organize/components/community-detail/posts-section.vue'),
    'community-owner-profile': () =>
      import('@/modules/community-organize/components/highlight-boards/community-owner-profile.vue'),
    'community-creator-info': () =>
      import('@/modules/community-organize/components/highlight-boards/community-creator-info.vue'),
    'rule-board': () => import('@/modules/community-organize/components/highlight-boards/rule-board.vue'),
    'faq-board': () => import('@/modules/community-organize/components/highlight-boards/faq-board.vue'),
    'top-contributors': () => import('@/modules/community-organize/components/highlight-boards/top-contributors.vue'),
    'most-active-post': () => import('@/modules/community-organize/components/highlight-boards/most-active-post.vue'),
    'manage-community-board': () =>
      import('@/modules/community-organize/components/highlight-boards/manage-community-board.vue'),
    'invitation-dialog': () =>
      import('@/modules/community-organize/components/private-dao/dialogs/invite-dialog/invite-layout.vue'),
    'reject-join-dao-dialog': () =>
      import('@/modules/community-organize/components/dao-detail-setting/dialogs/reject-join-dao-dialog.vue'),
  },
})
export default class CommunityDetail extends Vue {
  @Provide() vm = new CommunityDetailViewModel()
  createPostController = createPostController
  isOverlay = false
  hideFloatingButton = false

  @Watch('$route.params.id', { immediate: true }) idChange(val) {
    if (val) this.vm.loadData(val)
  }

  @Watch('$vuetify.breakpoint.width', { immediate: true }) onHideFloatingButton(width: number) {
    if (width >= 1280) {
      this.hideFloatingButton = true
      this.isOverlay = false
      return
    }
    this.hideFloatingButton = false
  }

  @Watch('$route.query', { immediate: true }) queryChange(query: { menu: string; tab: string; sortBy: string }) {
    if (query) {
      this.vm.fetchPostsByFilter({ ...query, _start: 0, _limit: 9 })
    }
  }

  copyLink() {
    navigator.clipboard.writeText(`https://dev-alpha.netlify.app${this.$route.fullPath}`)
  }

  overlay() {
    this.isOverlay = !this.isOverlay
  }

  startPosting() {
    createPostController.show(true, undefined, undefined, this.vm.communityProfile)
    this.vm.changeFirstTimeMemberVisitDao(false)
  }
  onPostNow() {
    this.isOverlay = false
    createPostController.show(true, undefined, undefined, this.vm.communityProfile)
  }

  destroyed() {
    this.vm.destroy()
  }
}
