import { appProvider } from '@/app-provider'
import { loadingController } from '@/components/global-loading/global-loading-controller'
import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { getFollowStatsNumberAfterSync, getPostStatsNumberAfterSync } from '@/helper/utils'
import { CommunityModel } from '@/models/community-model'
import { ProfileModel } from '@/models/profile-model'
import { createPostController } from '@/modules/common/dialogs/create-post/create-post-controller'
import { postController } from '@/modules/common/dialogs/post/post-controller'
import { apiService } from '@/services/api-services'
import { dispatcher, FollowingProfile, FollowTypeEnum, PostActionModel } from '@/stores/dispatcher'
import { PostStore } from '@/stores/post-store'
import { PostsStore } from '@/stores/posts-store'
import { walletStore } from '@/stores/wallet-store'
import { map } from 'lodash'
import { action, computed, IReactionDisposer, observable, reaction, toJS } from 'mobx'
import { asyncAction } from 'mobx-utils'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
const DEFAULT_CUSTOM_MENU = [
  { title: 'All topics', daoMenu: '', icon: require(`@/assets/icons/topic-icon.svg`) },
  { title: 'General', daoMenu: 'general', icon: require(`@/assets/icons/general-icon.svg`) },
  { title: 'Announcement', daoMenu: 'announcement', icon: require(`@/assets/icons/volumn-icon.svg`) },
]

const Tabs = [
  { title: 'All post', queryParam: 'all-post' },
  // { title: 'Like', queryParam: 'like' },
]

const SortBys = [
  { title: 'Latest', queryParam: 'latest' },
  { title: 'Top', queryParam: 'top' },
]

type Menu = {
  title?: string
  daoMenu?: string
  icon?: string
  id?: string
}

type Tab = {
  title?: string
  queryParam?: string
}

type SortBy = {
  title?: string
  queryParam?: string
}

export type FilterDaoDetail = {
  menu?: Menu
  tab?: Tab
  sortBy?: SortBy
}

const defaultFilter = {
  menu: DEFAULT_CUSTOM_MENU[0],
  tab: Tabs[0],
  sortBy: SortBys[0],
}

const defaultQuery = {
  tab: 'all-post',
  sortBy: 'latest',
}

export class CommunityDetailViewModel {
  @observable postsStore?: PostsStore
  @observable postListFilters = ['Latest', 'Top']
  @observable selectedPostFilter = this.postListFilters[0]
  @observable communityProfile?: CommunityModel = undefined
  @observable communityId = undefined as any
  @observable isDaoMember = false
  @observable joinDaoLoading = false
  @observable leaveDaoLoading = false
  @observable isListView = true
  @observable filters = {} as any
  @observable contributors?: ProfileModel[] = []
  @observable searchTextInput = ''
  @observable searchPostLoading = false
  @observable isShowPostBySearch = false
  @observable searchedPosts: PostStore[] = []
  @observable onwerPostCount: any = 0
  @observable menuFilter = undefined as any
  @observable userDao?: any = undefined
  @observable addToCustomFeedLoading = false
  @observable changeDaoTypeLoading = false
  @observable mostActivePostsStore?: PostsStore = undefined
  @observable isFirstTimeMemberVisitDao = false

  @observable openApplyToJoinDialog = false
  @observable applyToJoinStatus = 'default'
  @observable applyToJoinStatusLoading = false
  @observable applyToJoinLoading = false

  @observable showRejectedReasonDialog = false
  @observable rejectedDaoRequest: any = undefined
  @observable isBlockedFromDao = false

  @observable daoPosts: PostStore[] = []
  @observable daoDetailFilter = {}
  @observable daoDetailQuery = {}
  @observable daoDetailTotalActivePost = 0
  @observable postFetching = false
  @observable tabFilter = [
    { title: 'All posts', query: 'all-post' },
    { title: 'Likes', query: 'like' },
  ]
  @observable selectedTab = this.tabFilter[0]
  @observable totalDaoPosts = 0
  @observable loadingMore = false
  @observable loadMoreState = { _start: 0, _limit: 9 }
  @observable loadingData = false
  @observable pageLoaded = false

  @observable pinLoading = false
  @observable pinedPosts: PostStore[] = []

  private _disposers: IReactionDisposer[]
  private _unsubcrible = new Subject()

  @computed get postStores() {
    return this.postsStore?.posts
  }

  @asyncAction *changeFirstTimeMemberVisitDao(value) {
    this.isFirstTimeMemberVisitDao = value
    if (value && this.communityId) {
      yield apiService.userDao.memberVisitedDao(this.communityId)
    }
  }

  constructor() {
    this.daoDetailFilter = defaultFilter
    this.daoDetailQuery = defaultQuery

    this.menuFilter = DEFAULT_CUSTOM_MENU[0]
    this._disposers = [
      reaction(
        () => createPostController.completedUpdateType,
        (type) => {
          if (type) {
            createPostController.changeCompletedUpdateType(undefined)
            const query = appProvider.router.currentRoute.query
            this.fetchPostsByFilter({ ...query, _limit: 9, _start: 0 } as any)
          }
        }
      ),
      reaction(
        () => postController.completeUpdateType,
        (type) => {
          if (type) {
            postController.changeCompleteUpdateType(undefined)
            const query = appProvider.router.currentRoute.query
            this.fetchPostsByFilter({ ...query, _limit: 9, _start: 0 } as any)
          }
        }
      ),
      reaction(
        () => this.communityProfile,
        (community) => {
          if (community) {
            this.fecthOwnerInfo()
            this.fetchApplyToJoinStatus()
            this.checkBlockedUser()
          }
        }
      ),
    ]

    dispatcher.$profileChanged.pipe(takeUntil(this._unsubcrible)).subscribe((profile) => {
      this.syncFollowProfile(profile)
    })
    dispatcher.$postChanged.pipe(takeUntil(this._unsubcrible)).subscribe((postAction) => {
      this.syncPostAction(postAction)
    })
  }

  @observable isImageDAOLoading = false;

  @asyncAction *onCoverImageDAOChange(event) {
    try {
      this.isImageDAOLoading = true
      const file: File = event.target?.files[0]
      if (!file) return

      const fileS3 = yield apiService.daos.upload(file)

      const daoUpdated = yield apiService.daos.updateCoverDAO({
        daoId: this.communityProfile?._id,
        avatar: fileS3[0]?.id,
      })

      if (this.communityProfile) {
        this.communityProfile.avatar = daoUpdated.avatar
      }
    } catch (error) {
    } finally {
      this.isImageDAOLoading = false
    }
  }

  @action.bound changeFilter(filter: FilterDaoDetail) {
    this.daoDetailFilter = filter
    const query = this.buildQuery(filter)
    appProvider.router.replace({ query: query })
  }

  @action.bound onMenuChange(menu: string) {
    let selectedMenu: any = this.customMenu[0]
    if (this.menu) {
      selectedMenu = this.menu.find((item) => item.id === menu)
    }
    if (!selectedMenu) selectedMenu = this.customMenu.find((item) => item.daoMenu === menu)
    this.changeFilter({ ...this.daoDetailFilter, menu: selectedMenu })
  }

  @action.bound onTabChange(tab: string) {
    const selectedTab = this.tabFilter.find((item) => item.title === tab)
    if (selectedTab) {
      this.selectedTab = selectedTab
      const currentQuery = appProvider.router.currentRoute.query
      appProvider.router.replace({ query: { ...currentQuery, tab: selectedTab.query } })
    }
  }

  @action.bound onSortChange(value) {
    this.selectedPostFilter = value
    if (!this.communityId) return
    const currentQuery = appProvider.router.currentRoute.query
    if (this.selectedPostFilter === this.postListFilters[0]) {
      appProvider.router.replace({ query: { ...currentQuery, sortBy: SortBys[0].queryParam } })
    } else if (this.selectedPostFilter === this.postListFilters[1]) {
      appProvider.router.replace({ query: { ...currentQuery, sortBy: SortBys[1].queryParam } })
    }
    this.isShowPostBySearch = false
  }

  @asyncAction *fetchPostsByFilter(query: {
    menu: string
    tab: string
    sortBy: string
    _start: number
    _limit: number
  }) {
    try {
      this.postFetching = true
      if (this.communityId) {
        const [res] = yield Promise.all([
          yield apiService.daos.fetchDaoPosts(this.communityId, query),
          this.fetchPinedPosts(),
        ])

        if (res?.data?.length) {
          this.daoPosts = res.data.map((item) => new PostStore(item))
          this.totalDaoPosts = res.total
        }

        this.syncPinStatus(this.daoPosts)

        this.loadMoreState = { _start: 0, _limit: 9 }
      }
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.postFetching = false
    }
  }

  @asyncAction *loadMore() {
    try {
      this.loadingMore = true
      const query = appProvider.router.currentRoute.query
      this.loadMoreState = {
        ...query,
        _start: this.loadMoreState._start + this.loadMoreState._limit,
        _limit: this.loadMoreState._limit,
      }
      if (this.communityId) {
        const res = yield apiService.daos.fetchDaoPosts(this.communityId, { ...this.loadMoreState })
        const postMore = res.data
        const newPost = postMore.map((post) => new PostStore(post))
        const currentPost = this.daoPosts
        this.daoPosts = [...currentPost, ...newPost]
      }
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.loadingMore = false
    }
  }

  buildQuery = (filter: FilterDaoDetail) => {
    const { menu, tab, sortBy } = filter
    const query: any = {}
    if (menu) query.menu = menu.daoMenu || menu.id
    if (tab) query.tab = tab.queryParam
    if (sortBy) query.sortBy = sortBy.queryParam
    return query
  };

  @asyncAction *checkBlockedUser() {
    if (!walletStore.userProfile?._id) return
    try {
      const blockedUser = yield apiService.userDao.find(
        {
          dao: this.communityId,
          profile: walletStore.userProfile?._id,
          status: 'blocked',
        },
        { _limit: 1 }
      )
      this.isBlockedFromDao = blockedUser.length
    } catch (e) {
      console.log('Error checkBlockedUser', e)
    }
  }

  @action.bound setOpenApplyToJoinDialog(value: boolean) {
    this.openApplyToJoinDialog = value
  }

  @action.bound setShowRejectedReasonDialog(value: boolean) {
    this.showRejectedReasonDialog = value
  }

  @action syncPostAction(postAction: PostActionModel) {
    this.onwerPostCount = getPostStatsNumberAfterSync(this.onwerPostCount, postAction.type)
    this.communityProfile = {
      ...this.communityProfile,
      totalPosts: getPostStatsNumberAfterSync(this.communityProfile?.totalPosts, postAction.type),
    }
  }

  @action syncFollowProfile(profile: FollowingProfile) {
    if (profile.type === FollowTypeEnum.dao && this.communityProfile?._id === profile.to) {
      this.communityProfile = {
        ...this.communityProfile,
        totalFollowers: getFollowStatsNumberAfterSync(this.communityProfile.totalFollowers, profile.followState, 0),
      }
      return
    }
    if (!this.contributors?.length) return
    const userIndex = this.contributors.findIndex((item) => item._id === profile.to)
    if (userIndex !== -1) {
      this.contributors[userIndex] = {
        ...this.contributors[userIndex],
        isFollowing: profile.followState,
        loading: false,
      }
    }
  }

  @asyncAction *fecthOwnerInfo() {
    try {
      if (!this.communityId || !this.communityProfile?.profile?._id) return
      this.onwerPostCount = yield apiService.posts.count({
        dao: this.communityId,
        profile: this.communityProfile?.profile?._id,
      })
    } catch (e) {
      console.log('fetchOwnerInfo', e)
      this.onwerPostCount = 0
    }
  }

  @asyncAction *fetchApplyToJoinStatus() {
    try {
      this.applyToJoinStatusLoading = true
      const applyToJoinRequest = yield apiService.daoRequest.find({
        dao: this.communityId,
        profile: walletStore.userProfile?._id,
        _limit: 1,
        _sort: 'updatedAt:desc',
      })
      if (applyToJoinRequest.length && applyToJoinRequest[0].status === 'pending') {
        this.applyToJoinStatus = 'pending'
      } else if (applyToJoinRequest[0].status === 'rejected' && !applyToJoinRequest[0].confirmed) {
        this.rejectedDaoRequest = applyToJoinRequest[0]
        this.setShowRejectedReasonDialog(true)
      }
    } catch (error) {
    } finally {
      this.applyToJoinStatusLoading = false
    }
  }

  @asyncAction *applyToJoinPrivateDao() {
    try {
      this.applyToJoinLoading = true
      if (this.communityId) {
        yield apiService.daos.applyToJoin(this.communityId)
        this.applyToJoinStatus = 'pending'
        snackController.success('Request have sent, please wait admin approve')
        this.setOpenApplyToJoinDialog(false)
      }
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.applyToJoinLoading = false
    }
  }

  @action destroy() {
    this._unsubcrible.next()
    this._unsubcrible.complete()
    this._disposers.forEach((d) => d())
    this.postsStore?.destroy()
    this.searchedPosts.forEach((postStore) => postStore.destroy())
    this.mostActivePostsStore?.destroy()
  }

  @action changeListView(value) {
    this.isListView = value
  }

  @action clearData() {
    this.isDaoMember = false
    this.communityId = undefined
  }

  @asyncAction *loadData(id?: string) {
    try {
      this.loadingData = true
      if (!id || id == process.env.VUE_APP_RECRUITMENT_ID) return
      this.clearData()
      this.communityId = id as string
      this.postFetching = true
      this.communityProfile = yield apiService.daos.fetchDao(id)
      this.contributors = yield apiService.daos.getContributors(this.communityId)
      if (!this.isOwnerDao && this.communityProfile) {
        this.isDaoMember = yield apiService.daos.checkDaoMember({ daoId: this.communityId })
      }

      this.mostActivePostsStore = new PostsStore({ _limit: 4, _sort: 'score:desc', dao: this.communityId })
      yield this.fetchUserDao()

      // if (!this.privateDaoNoPermission) this.fetchPosts()
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.loadingData = false
      this.pageLoaded = true
      setInterval(() => {
        this.searchPostLoading = false
      }, 2000)
    }
  }

  @asyncAction *searchPost() {
    if (!this.searchTextInput || this.searchTextInput.trim().length === 0) {
      this.isShowPostBySearch = false
      return
    }
    if (!this.communityId) return
    try {
      this.postFetching = true
      const posts = yield apiService.daos.searchPost(this.searchTextInput, this.communityId, this.menuFilter.daoMenu)
      if (posts && posts.length) {
        this.searchedPosts = posts.map((item) => new PostStore(item))
      } else this.searchedPosts = []
      this.isShowPostBySearch = true
    } catch (e) {
      snackController.commonError(e)
    } finally {
      this.postFetching = false
    }
  }

  @asyncAction *joinDao() {
    // if (!walletStore.verifyUserAction()) return
    try {
      this.joinDaoLoading = true
      yield apiService.daos.joinDao({ daoId: this.communityId })
      this.isDaoMember = true
      snackController.success('Join DAO successfully')
      if (this.communityProfile?.classification === 'private') this.loadData(this.communityId)
      else this.fetchUserDao()
    } catch (e) {
      snackController.commonError(e)
    } finally {
      this.joinDaoLoading = false
    }
  }

  @asyncAction *leaveDao() {
    // if (!walletStore.verifyUserAction()) return
    try {
      this.leaveDaoLoading = true
      yield apiService.daos.leaveDao({ daoId: this.communityId })
      this.isDaoMember = false
      snackController.success('Leave DAO successfully')
      if (this.communityProfile?.classification === 'private') this.loadData(this.communityId)
    } catch (e) {
      snackController.commonError(e)
    } finally {
      this.leaveDaoLoading = false
    }
  }

  //top contributors action
  @asyncAction *followUser(item: any) {
    // if (!walletStore.verifyUserAction()) return
    try {
      item.loading = true
      yield apiService.userFollows.followUser({ follow: item.id })
      item.isFollowing = true
    } catch (error) {
      snackController.commonError(error)
    } finally {
      item.loading = false
    }
  }

  @asyncAction *unFollowUser(item: any) {
    // if (!walletStore.verifyUserAction()) return
    try {
      item.loading = true
      yield apiService.userFollows.unFollowUser({ follow: item.id })
      item.isFollowing = false
    } catch (error) {
      snackController.commonError(error)
    } finally {
      item.loading = false
    }
  }

  @asyncAction *fetchUserDao() {
    const userDaos = yield apiService.userDao.find({ dao: this.communityId, profile: walletStore.userProfile?._id })
    if (userDaos) this.userDao = userDaos[0]
    if (this.userDao && this.userDao.isFirstVisit) {
      this.userDao.isFirstVisit = false
      this.changeFirstTimeMemberVisitDao(true)
    }
  }

  @asyncAction *addToCustomFeed(state: boolean) {
    try {
      this.addToCustomFeedLoading = true
      if (this.communityId) {
        const res = yield apiService.userDao.addToFeed({ daoId: this.communityId, state })
        if (res) {
          if (state) snackController.success('Add to feed successfully')
          else snackController.success('Remove on feed successfully')
          yield this.fetchUserDao()
        }
      }
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.addToCustomFeedLoading = false
    }
  }

  @asyncAction *changeDaoType(type: 'public' | 'private') {
    try {
      this.changeDaoTypeLoading = true
      if (this.communityId) {
        const res = yield apiService.daos.changeDaoType(this.communityId, { type: type })
        if (res) {
          snackController.success(`Dao have changed to ${type}`)
          this.communityProfile = { ...this.communityProfile, classification: type }
        }
      }
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.changeDaoTypeLoading = false
    }
  }

  @asyncAction *changeInvitation(value: boolean) {
    try {
      if (this.communityId) {
        if (value) snackController.success('Invitation is active')
        else snackController.success('Invitation is disable')
        this.communityProfile = { ...this.communityProfile, invitation: value }
        yield apiService.daos.changeInvitation(this.communityId, { invitation: value })
      }
    } catch (error) {
      snackController.commonError(error)
    }
  }

  @asyncAction *confirmRejectReason() {
    try {
      yield apiService.daoRequest.markRequestConfirm(this.rejectedDaoRequest.id)
      this.setShowRejectedReasonDialog(false)
    } catch (error) {}
  }

  @action.bound get4LastCharacter(profile: ProfileModel) {
    const profileWalletAddress = profile?.walletAddress
    if (profileWalletAddress) return profileWalletAddress.substr(profileWalletAddress.length - 4)
    else return ''
  }

  @asyncAction *changePinPost(postStore: PostStore, forceCheck = false) {
    this.pinLoading = true
    try {
      const postId = postStore.post.id
      if (!postId) return
      postStore.pinLoading = true
      if (!postStore.isPinded) yield apiService.daos.pinPost(this.communityId, { postId })
      else yield apiService.daos.unPinPost(this.communityId, { postId })
      postStore.togglePinMode()
      this.fetchPinedPosts()
      if (forceCheck) {
        const targetPostStore = this.daoPosts?.find((postStore) => postStore?.post?.id === postId)
        if (targetPostStore) targetPostStore.isPinded = postStore.isPinded
      }
    } catch (e) {
      snackController.commonError(e)
    } finally {
      postStore.pinLoading = false
      this.pinLoading = false
    }
  }

  @asyncAction *fetchPinedPosts() {
    try {
      const res = yield apiService.daos.fetchPinnedPosts(this.communityId)
      this.pinedPosts = res.map((post) => {
        const postStore = new PostStore(post)
        postStore.isPinded = true
        return postStore
      })
    } catch (e) {
      snackController.commonError(e)
    }
  }

  @computed get pinedPostId() {
    return map(this.pinedPosts, 'post.id') || []
  }

  @action syncPinStatus(postStores: PostStore[]) {
    postStores.map((postStore) => {
      if (this.pinedPostId.includes(postStore.post.id)) postStore.isPinded = true
    })
  }

  @computed get avatarUrl() {
    return this.communityProfile?.avatar?.url
  }
  @computed get snsLinks() {
    return this.communityProfile?.snsLink?.list
  }
  @computed get customSnsLinks() {
    return this.communityProfile?.snsLink?.list?.map((item) => ({
      link: item,
      domain: new URL(item),
    }))
  }
  @computed get categories() {
    return this.communityProfile?.categories
  }
  @computed get rules() {
    return this.communityProfile?.rule?.list || []
  }
  @computed get faqs() {
    return this.communityProfile?.faq?.list || []
  }
  @computed get menu() {
    return this.communityProfile?.menu?.list
  }
  @computed get owner() {
    return this.communityProfile?.profile
  }

  @computed get isOwnerDao() {
    return this.owner && this.owner._id === walletStore.userProfile?._id
  }

  @computed get isContributorDao() {
    return map(this.contributors, 'id').includes(walletStore.userProfile?._id)
  }

  @computed get isAdmin() {
    return this.isOwnerDao || (this.isContributorDao && this.isDaoMember)
  }

  @computed get mostActivePosts() {
    return this.mostActivePostsStore?.posts
  }

  @computed get customMenu() {
    return this.menu?.length
      ? [...DEFAULT_CUSTOM_MENU, ...this.menu.map((item) => ({ title: item.title, daoMenu: item.id }))]
      : DEFAULT_CUSTOM_MENU
  }

  @computed get addToCustomFeedState() {
    if ((this.isOwnerDao || this.isDaoMember) && this.userDao) {
      if (!this.userDao.addToFeed) {
        return 'add'
      } else {
        return 'remove'
      }
    } else {
      return 'hide'
    }
  }

  @computed get privateDaoNoPermission() {
    return !this.userDao && this.communityProfile?.classification === 'private'
  }

  @computed get invitationActive() {
    return this.communityProfile?.invitation
  }

  @computed get daoTypeDisplayed() {
    return this.communityProfile?.classification === 'public' ? 'Public' : 'Private'
  }

  @computed get isPublicDAO() {
    return this.communityProfile?.classification === 'public'
  }

  @computed get showJoinLeaveButton() {
    return !this.isOwnerDao && (this.isPublicDAO || this.isDaoMember)
  }

  @computed get showApplyToJoinButton() {
    return !this.isAdmin && !this.isPublicDAO && !this.isDaoMember
  }

  @computed get rejectReason() {
    return this.rejectedDaoRequest.reason
  }

  @computed get tabs() {
    return this.tabFilter.map((tab) => tab.title)
  }

  @computed get tab() {
    return this.selectedTab.title
  }

  @computed get postsDisplayed() {
    return this.isShowPostBySearch ? this.searchedPosts : this.daoPosts?.filter((postStore) => !postStore.isPinded)
  }

  @computed get daoDetailPostsCanLoadMore() {
    return this.postsDisplayed.length < this.totalDaoPosts
  }
}
